<template>
  <div class="course-wrapper">
    <router-link :to="`/course-info/${course.id}/program`" class="course-wrapper__course">

      <div class="course-wrapper__course-logo"
           :style="{background: course.color, borderColor: course.color}">
        <el-image class="course-wrapper__course-picture" :src="course.picture">
          <div slot="error">
            <img src="@/assets/img/user/no-courses.svg" alt="">
          </div>
        </el-image>
      </div>
      <div class="course-wrapper__main">
        <div class="course-wrapper__course-info">
          <div class="course-wrapper__course-title">{{ course.name }}</div>
          <div class="course-wrapper__course-footer">
            {{ numberVideosCount(course.lessonsCount || 0) }}
<!--            <div class="course-wrapper__course-footer-delimiter"/>-->
<!--            {{ numberTasksCount(course.tasksCount) }}-->
          </div>

          <div class="course-wrapper__course-status">
              <div class="course-wrapper__course-status-text">
                Курс пройден успешно
              </div>
              <i class="el-icon-circle-check"/>
          </div>

        </div>

        <div class="course-wrapper__course-passed" @click.prevent="downloadCertificate">
          <div class="course-wrapper__course-passed-btn">
            <img class="course-wrapper__icon-btn-certificate" src="@/assets/img/user/profile/icon-btn-certificate.svg" alt="">
          </div>
          <div class="course-wrapper__course-passed-text">Скачать</div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>

import {coursesService} from "../../../courses/courses.service";

export default {
  name: "CompletedCourseItem",
  props: {
    course: Object
  },
  methods: {
    numberVideosCount(videosCount) {
      let title = ''
      let lastNumber = Number(videosCount.toString()[videosCount.toString().length - 1])

      if (lastNumber === 1)
        title = `${videosCount} урок`
      else if (lastNumber > 1 && lastNumber < 5)
        title = `${videosCount} урока`
      else if ((lastNumber >= 5 && lastNumber <= 9) || lastNumber === 0)
        title = `${videosCount} уроков`

      if (videosCount >= 10 && videosCount <= 20)
        title = `${videosCount} уроков`

      return title
    },
    numberTasksCount(tasksCount) {
      let title = ''
      let lastNumber = Number(tasksCount.toString()[tasksCount.toString().length - 1])

      if (lastNumber === 1)
        title = `${tasksCount} задание`
      else if (lastNumber > 1 && lastNumber < 5)
        title = `${tasksCount} задания`
      else if ((lastNumber >= 5 && lastNumber <= 9) || lastNumber === 0) {
        title = `${tasksCount} заданий`
      }
      if (tasksCount >= 10 && tasksCount <= 20)
        title = `${tasksCount} заданий`

      return title
    },
    downloadCertificate() {
      coursesService.downloadCertificate(this.course.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.course-wrapper {
  display: flex;
  align-items: center;

  margin-bottom: 16px;

  border: 1px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 20px;

  &__course {
    display: flex;
    width: 100%;

    text-decoration: none;

    transition: 0.2s;

    &:hover {
      .course-wrapper__course-logo:before {
        transition: 400ms;
        bottom: 0;
        height: 100%;
      }
    }
    @media screen and (max-width: 540px) {
      flex-direction: column-reverse;
      justify-content: space-between;
      height: 294px;
    }
  }

  &__main {
    display: flex;
    margin: 24px 20px;

    flex: 1;
    transition: 0.2s;

    @media screen and (max-width: 980px) {
      flex-direction: column;
      justify-content: space-between;
      margin: 16px 24px;
    }
    @media screen and (max-width: 540px) {
      margin: 16px 14px;
    }
  }

  &__course-logo {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 180px;
    height: 151px;

    border-radius: 20px;

    transition: 0.2s;

    &:before {
      content: '';
      position: absolute;
      bottom: 50%;
      left: 0;
      width: 100%;
      height: 0;
      background: rgba(255, 255, 255, 0.08);
      border-radius: 5px;
    }

    &:hover:before {
      transition: 400ms;
      bottom: 0;
      height: 100%;
    }

    @media screen and (max-width: 980px) {
      width: 200px;
      height: 200px;
    }
    @media screen and (max-width: 540px) {
      justify-content: center;

      width: 100%;
      height: 120px;
    }
  }

  &__course-picture {

  }

  &__course-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    flex: 1;

    transition: 0.2s;
    @media screen and (max-width: 980px) {
      margin-bottom: 20px;
    }
    @media screen and (max-width: 540px) {
      margin-bottom: 14px;
    }
  }

  &__course-title {
    font-family: Raleway;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
    overflow: hidden;

    color: #121212;
    @media screen and (max-width: 980px) {
      font-size: 18px;
    }
    @media screen and (max-width: 540px) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__course-status {
    display: flex;
    align-items: center;

    i {
      color: #6ac035;
      margin-left: 8px;
    }
  }
  &__course-status-text {
    display: inline;
    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #121212;

    @media screen and (max-width: 605px) {
      max-width: 180px;
    }
    @media screen and (max-width: 540px) {
      max-width: max-content;
    }

    @media screen and (max-width: 380px) {
      max-width: 180px;
    }
  }

  &__course-no-passed{
    display: flex;
    align-items: center;

    @media screen and (max-width: 605px) {
      align-items: flex-start;
    }
  }
  &__icon-certificate{
    margin-top: 3px;
    margin-left: 10px;

    @media screen and (max-width: 605px) {
      margin-top: 0px;
    }
  }

  &__course-footer {
    display: flex;
    align-items: center;

    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #121212;
  }

  &__course-footer-delimiter {
    content: '';
    width: 4px;
    height: 4px;

    margin-left: 10px;
    margin-right: 10px;
    border-radius: 100%;
    background: #000000;
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 252px;
    height: 52px;

    margin-top: auto;
    margin-bottom: auto;

    background: #4072ef;
    border-radius: 100px;

    text-decoration: none;
    cursor: pointer;

    transition: 0.2s;

    &:hover {
      background-color: #407BFF;
    }

    @media screen and (max-width: 980px) {
      margin: 0px;
    }
    @media screen and (max-width: 540px) {
      width: 242px;
      margin: 0 auto;
    }
    @media screen and (max-width: 480px) {
      height: 46px;
    }
  }

  &__btn-title {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #FFFFFF;
  }

  &__btn-icon {
    margin-left: 15px;
  }

  &__course-passed {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: auto 0px;

    width: 252px;
    cursor: pointer;

    @media screen and (max-width: 980px) {
      width: max-content;
      margin-top: -10px;
    }
    @media screen and (max-width: 540px) {
      margin-left: auto;
      margin-right: auto;
      margin-top: -7px;
      margin-bottom: -7px;
    }
  }
  &__course-passed-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 48px;
    height: 48px;

    background: #407BFF;
    border-radius: 8px;

    transition: 0.2s;
    @media screen and (max-width: 540px) {
      width: 44px;
      height: 44px;
    }

    &:hover {
      background: #1454F2;
    }
  }
  &__course-passed-text {
    font-family: Raleway;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0em;
    color: #1454F2;

    margin-top: 5px;
  }
}
</style>

<style lang="scss">
.course-wrapper {
  &__course-picture {
    img {
      object-fit: contain;
      width: 110px;
      height: 110px;

      border-radius: 4px;
      @media screen and (max-width: 980px) {
        width: 148px;
        height: 100%;
        max-height: 148px;
      }
      @media screen and (max-width: 540px) {
        height: 100%;
        max-height: 110px;
        max-width: 200px;
      }
    }

    .el-image__inner {
      object-fit: contain;
      width: 145px;
      height: 100%;
      max-height: 140px;

      border-radius: 4px;
      @media screen and (max-width: 980px) {
        width: 170px;
        height: 100%;
        max-height: 148px;
      }
      @media screen and (max-width: 540px) {
        height: 100%;
        max-height: 110px;
        width: auto;
      }
    }
  }
}
</style>
