<template>
  <div class="courses-list-wrapper">
    <div class="courses-list-wrapper__courses"
         v-for="category in categories" :key="`category-${category.id}`">

      <div class="courses-list-wrapper__category">{{ category.name }}</div>

      <transition-group appear name="el-fade-in">
        <div class="courses-list-wrapper__course"
             v-for="course in filterCourse.filter(item => item.categories.find(categoryId => categoryId === category.id))"
             :key="`course-${course.id}`">
          <completed-course :course="course"/>
        </div>
      </transition-group>

    </div>

    <div v-if="isCourseWithoutCategory" class="courses-list-wrapper__courses-no-category">
      <div class="courses-list-wrapper__category">Курсы без направлений</div>

      <transition-group appear name="el-fade-in">
        <div class="courses-list-wrapper__course"
             v-for="course in filterCourse.filter(item => item.categories.length === 0)"
             :key="`course-${course.id}`">
          <completed-course :course="course"/>
        </div>
      </transition-group>
    </div>

    <transition name="el-fade-in" v-if="!filterCourse.length">
      <courses-list-empty/>
    </transition>

  </div>
</template>

<script>
import CompletedCourse from "./CompletedCourseItem";
import CoursesListEmpty from './CoursesListEmpty'
import {mapGetters} from "vuex";

export default {
  name: "CompletedCourseList",
  components: {
    CompletedCourse,
    CoursesListEmpty
  },
  computed: {
    ...mapGetters(['searchLine','myCourses']),


    allCategories() {
      return this.$store.state.courses.categories
    },
    filterCourse() {
      return this.myCourses.filter(course => course.certificateAvailable)
    },
    categories() {
      const categoriesIds = []
      this.filterCourse.forEach(course => {
        course.categories.forEach(item =>{
          if(!categoriesIds.find(id => id === item))
            categoriesIds.push(item)
        })
      })

      return categoriesIds.map(categoryId => ({
        ...this.allCategories.find(category => category.id === categoryId)
      }))
    },
    isCourseWithoutCategory() {
      return this.filterCourse.filter(course => course.categories.length === 0).length
    }
  },
  methods: {
    isMatches(name, search) {
      return name.toLowerCase().startsWith(search.toLowerCase())
    }
  }
}
</script>

<style lang="scss" scoped>
.courses-list-wrapper {
  &__courses {
    display: flex;
    flex-direction: column;

    margin-top: 20px;
    .courses-list-wrapper__course:last-child{
      margin-bottom: 0px;
    }
  }
  &__courses-no-category {
    display: flex;
    flex-direction: column;

    margin-top: 26px;
  }

  &__course{
    margin-bottom: 20px;
    .course-wrapper {
      margin-bottom: 0px;
    }
  }
  &__category {
    font-family: Raleway;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: #121212;

    margin-bottom: 16px;

    @media screen and (max-width: 400px) {
      font-size: 16px;
      line-height: 24px;
      transition: 0.2s;
    }
  }
}
</style>
