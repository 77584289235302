<template>
  <div class="my-courses-header">
    <div class="my-courses-header__title">Мои курсы</div>

    <div class="my-courses-header__main">
      <search-input @changeSearch="changeSearch"/>
      <div class="my-courses-header__btn-group">
        <div class="my-courses-header__btn"
             :class="{'my-courses-header__btn--active':typeCourses}"
             @click="setTypeCourses(true)">Текущие
        </div>
        <div class="my-courses-header__btn"
             :class="{'my-courses-header__btn--active': !typeCourses}"
             @click="setTypeCourses(false)">Пройденные
        </div>
        <div class="my-courses-header__btn-animation"
             :class="{'my-courses-header__btn-animation--right': !typeCourses}"/>
      </div>
    </div>

    <el-divider/>
  </div>
</template>

<script>
import SearchInput from "../../components/SearchInput";
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "CoursesHeader",
  components: {
    SearchInput
  },
  data() {
    return {
      selectBtn: true
    }
  },
  computed: {
    ...mapGetters(['typeCourses']),
  },
  mounted() {
    this.$store.commit('myCourses.setSearchCourseName',"")
  },
  methods: {
    ...mapMutations(['setTypeCourses']),

    changeSearch(search) {
      this.$store.commit('myCourses.setSearchCourseName',search)
    },
  }
}
</script>

<style lang="scss" scoped>
.my-courses-header {
  margin-bottom: 32px;

  &__title {
    font-family: Raleway, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
    color: #121212;

    margin-bottom: 32px;
    transition: 0.2s;

    @media screen and (max-width: 980px) {
      font-size: 28px;
      margin-bottom: 16px;
    }
    @media screen and (max-width: 700px) {
      margin-bottom: 12px;
    }
  }

  &__main {
    display: flex;
    @media screen and (max-width: 700px) {
      flex-direction: column;
    }
  }

  &__btn-group {
    position: relative;

    display: flex;
    margin-left: 32px;
    max-width: max-content;
    transition: all 0.2s;

    @media screen and (max-width: 980px) {
      margin-left: 20px;
    }
    @media screen and (max-width: 700px) {
      margin-left: 0px;
    }

    @media screen and (max-width: 700px) {
      margin-top: 12px;
    }
  }

  &__btn-animation {
      content: '';
      position: absolute;

      top: 0;
      left: 2px;

      width: 128px;
      height: 40px;
      border-radius: 100px;
      background: #1454F2;

      z-index: -1;
      transition: all .2s cubic-bezier(0.215, 0.61, 0.355, 1);

      &--right {
        width: 142px;
        left: 48%;
      }
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 40px;
    max-width: max-content;

    padding-left: 32px;
    padding-right: 32px;

    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #121212;

    cursor: pointer;

    transition: 0.2s;

    box-sizing: border-box;
    border: 2px solid transparent;

    &--active {
      color: #FFFFFF;
    }
  }

  .el-divider--horizontal {
    position: absolute;
    left: 0px;
    top: 246px;
    margin: 0px;

    transition: 0.2s;

    @media screen and (max-width: 980px) {
      top: 212px;
    }
    @media screen and (max-width: 700px) {
      top: 260px;
    }
    @media screen and (max-width: 600px) {
      top: 250px;
    }
  }
}
</style>