<template>
  <div class="my-courses-wrapper">
    <courses-header/>
    <transition appear name="el-fade-in" duration="100" mode="out-in">

      <div v-if="!loading">
        <courses-list v-if="typeCourses"/>
        <completed-courses-list v-else/>
      </div>

      <div v-else v-loading="loading"
           class="my-courses-wrapper__loading"
           element-loading-spinner="el-icon-loading"/>
    </transition>
  </div>
</template>

<script>
import CoursesHeader from "./components/CoursesHeader";
import CoursesList from "./components/coursesList/CoursesList";
import CompletedCoursesList from "./components/coursesList/CompletedCoursesList";

import {mapGetters} from "vuex";
import {myCoursesService} from "./myCourses.service";

export default {
  name: "MyCourses",
  components:{
    CoursesList,
    CompletedCoursesList,
    CoursesHeader
  },
  computed:{
    ...mapGetters(['typeCourses'])
  },
  data() {
    return {
      loading: false
    }
  },
  created() {
    this.loading = true
    myCoursesService.getMyCourses().finally(() => {this.loading = false})
  },
  beforeDestroy() {
    this.$store.state.myCourses.searchCourseName = ''
  },
  watch: {
    typeCourses(){
      this.startPreloader()
    }
  },
  methods: {
    startPreloader() {
      this.loading = true
      setTimeout(() => this.loading = false, 300)
    },
  }
}
</script>

<style lang="scss">
.my-courses-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  margin-top: 20px;
  transition: 0.2s;

  @media screen and (max-width: 980px) {
    padding-left: 24px;
    padding-right: 24px;

    box-sizing: border-box;
  }

  &__loading {
    height: 50vh;
    width: 100%;
    font-size: 30px;
  }
}
</style>
