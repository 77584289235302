<template>
  <div class="my-courses-empty">
    <div class="my-courses-empty__title">У вас еще нет курсов</div>
    <div class="my-courses-empty__picture">
      <img src="@/assets/img/user/no-courses.svg" alt="no-course">
    </div>
    <router-link to="/courses" v-if="typeCourses" class="my-courses-empty__btn">Выберите курс</router-link>
  </div>
</template>

<script>
export default {
  name: "MyCoursesEmpty",
  computed :{
    typeCourses(){
      return this.$store.state.myCourses.typeCourses
    }
  }
}
</script>

<style lang="scss" scoped>
.my-courses-empty {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 50px;

  &__title {
    font-family: Raleway;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: #121212;

    margin-bottom: 35px;
  }

  &__picture {
    margin-bottom: 100px;
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 257px;
    height: 64px;

    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;

    text-decoration: none;
    color: #FFFFFF;

    border-radius: 50px;
    background: #1454F2;

    cursor: pointer;

    &:hover {
      background-color: #4072ef;
    }
  }
}
</style>
